import api from '@/services/api/api';

export const useNews = (
  params = {
    page,
    filters: {},
  },
) => {
  const news = useInfiniteQuery(
    ['news', params.filters],
    async ({ pageParam = 1 }) =>
      await api.news.getNews(
        {
          per_page: params.per_page,
          page: pageParam,
          isMainPage: params.isMainPage || false,
        },
        toValue(params.filters),
      ),
    {
      staleTime: Infinity,
      select: ({ pages }) => pages.flatMap(({ data }) => data?.data),
      getNextPageParam: ({ data }) => {
        return data.meta.current_page < data.meta.last_page
          ? data.meta.current_page + 1
          : undefined;
      },
      refetchOnMount: true,
    },
  );

  const queryClient = useQueryClient();

  const resetPage = (filters) => {
    queryClient.setQueryData(['news', filters], (prevData) => {
      if (!prevData) return;
      return {
        pages: [prevData.pages[0]],
        pageParams: [prevData.pageParams[0]],
      };
    });
  };

  news.suspense();

  return {
    news,
    resetPage,
  };
};
