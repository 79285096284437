<template>
  <div>
    <NuxtLink
      :to="getUrl"
      class="group flex flex-row rounded-[12px] text-new-black shadow-drop-0"
      :class="isMainPage ? 'p-[10px] ' : 'p-[6px] xl:p-5 '"
    >
      <div class="relative max-h-[308px] w-full max-w-[308px]">
        <MainPageCardsReworkedCommonCardImageBlock
          v-bind="{ data }"
          disable-label
          :aspect="aspect"
        />
      </div>

      <div
        class="flex min-w-[58.93108%] max-w-[58.93108%] flex-col p-[10px] pt-0 font-roboto"
      >
        <div
          v-if="!disableLabel"
          class="z-20 mb-[5px] w-fit rounded-[4px] py-px pl-[12px] pr-[4px] text-[12px] font-bold uppercase leading-[15px] text-white-100 xl:text-[14px] xl:leading-[17px]"
          :class="getDataByModel?.labelBgColor"
        >
          <slot name="badge">
            {{ data?.card_label || getDataByModel?.label }}
          </slot>
        </div>
        <h2
          class="text-clamp-3 text-[12px] font-medium leading-[16px] xl:mb-4"
          :class="
            isMainPage
              ? '2xl:text-[18px] 2xl:leading-[23px] '
              : 'xl:text-[16px] xl:leading-[19px] 2xl:text-[22px] 2xl:leading-[29px] overflow-hidden '
          "
        >
          {{ getTitle }}
        </h2>

        <span
          v-if="isDesktop"
          class="text-clamp-3 hidden text-[10px] leading-[9px] md:text-[14px] md:leading-[17px] 2xl:inline-flex"
        >
          {{ data?.announce }}
        </span>

        <div
          class="mt-auto flex items-center gap-[8px] font-roboto text-[12px] font-semibold leading-[14px] text-[#808080]"
        >
          <div class="size-[12px]" :class="getDataByModel.labelBgColor" />
          {{ new Intl.DateTimeFormat().format(new Date(data.published_at)) }}
        </div>
      </div>
    </NuxtLink>
  </div>
</template>

<script setup>
const props = defineProps({
  data: {
    type: Object,
    default: () => ({}),
    require: true,
  },
  titleLength: {
    type: Number,
    default: 0,
  },
  disableLabel: { type: Boolean, default: false },
  aspect: {
    type: Object,
    default: () => ({
      w: 1,
      h: 1,
    }),
  },
});

const route = useRoute();
const getCardSettings = useCardSettings();
const { isDesktop } = useBreakpoint();

const getDataByModel = computed(() => getCardSettings(props.data));

const getTitle = computed(() => {
  if (!props.titleLength) {
    return props.data.title;
  }

  const splitText = props.data.title.split('');
  return splitText.length > props.titleLength
    ? `${splitText.slice(0, props.titleLength).join('')}...`
    : props.data.title;
});

const isMainPage = computed(() => {
  return route.path === '/';
});

const getUrl = computed(() => {
  return (
    props.data?.url ?? `${getDataByModel?.value?.hrefBase}${props.data?.slug}`
  );
});
</script>
